import React, { useRef, useState, useEffect } from "react"
import ScrollContainer from "react-indiana-drag-scroll"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import SquereImg from "../components/SquereImg"
import CollapsableBox from "../components/CollapsableBox"
import ProjectPostLink from "../components/ProjectPostLink"
import ArrowRight from "../components/arrowRight"
import ContactSection from "../components/ContactSection"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ResponsiveImage from "../components/ResponsiveImage"

import {
  setScrollAnimation,
  addImageScrollAnimation,
} from "../helpers/animations"

import mainPageData from "../../site/pages/main_page.json"
import styled from "styled-components"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

// import LocomotiveScroll from "locomotive-scroll"

export const MAX_WIDTH = 1360
export const WIDTH_PERCENT = 0.95

const HeroImgContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media only screen and (min-width: 62em) {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  }
`

const StyledContainer = styled.div`
  @media only screen and (min-width: 62em) {
    height: 100%;
    align-items: center;
  }
`
const AnimationTitleBackground = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
`

const Title = styled.h2`
  font-size: 4.5rem;
  letter-spacing: 1.57rem;
  font-weight: 700;
  line-height: 105%;
  margin: 0 0 2rem;
  text-transform: none;
  position: relative;

  span {
    display: block;
  }

  @media only screen and (min-width: 62em) {
    margin: 2rem 0;
  }

  @media only screen and (min-width: 90em) {
    font-size: 6.8rem;
    letter-spacing: 2.14rem;
    margin-bottom: 2.5rem;
  }
`

const ThinTitle = styled.span`
  font-weight: 200;
  letter-spacing: 0.09rem;

  @media only screen and (min-width: 90em) {
    letter-spacing: 0;
  }
`

const StyledButtonLink = styled(AniLink)`
  margin-top: 1rem;

  @media only screen and (min-width: 62em) {
    margin-top: 5rem;
  }
`

const StyledTitleLink = styled(AniLink)`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: var(--black);
  }
`

const StyledTitleLinkHover = styled.span`
  max-width: 0;
  display: inline-flex;
  overflow: hidden;
  transition: all ease-in-out 0.3s;

  ${StyledTitleLink}:focus &, ${StyledTitleLink}:hover & {
    max-width: 200px;
    margin-right: 0.8rem;
  }
`

const WhatWeDo = styled.div`
  margin-top: 8rem;
`

const Slider = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3rem;
`
const StyledProjectPostLink = styled(ProjectPostLink)`
  width: ${({ projectWidth }) => projectWidth}px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0 calc(var(--base-grid-gutter) / 2);
`
const StyledScrollContainer = styled(ScrollContainer)`
  padding-left: ${({ scrollPaddingLeft }) => scrollPaddingLeft}px;
  /* scroll-behavior: smooth; */
`

const MainPage = ({ data, location }) => {
  gsap.registerPlugin(ScrollTrigger)

  const scrollEl = useRef(null)
  const heroRef = useRef(null)
  const sectionsRefs = useRef([])
  sectionsRefs.current = []

  // const siteTitle = `Title`
  const projects = data.allMarkdownRemark.edges

  const [projectWidth, setProjectWidth] = useState(0)
  const [clearScrollAnimation, setClearScrollAnimation] = useState(null)
  const [scrollPaddingLeft, setScrollPaddingLeft] = useState(0)
  const [startTl] = useState(
    gsap.timeline({
      paused: true,
      defaults: {
        ease: "power4",
        opacity: 0,
        transformOrigin: "left center",
        duration: 0.6,
      },
    })
  )
  // const [sectionsTl] = useState(
  //   gsap.timeline({
  //     paused: true,
  //     defaults: {
  //       // ease: "power",
  //     },
  //   })
  // )

  let [locoScroll, setLocoScroll] = useState(null)
  // const [lastScrollPosition, setLastScrollPosition] = useState(0)

  // const setScrollPosition = event => {
  //   let scrollPosition = event

  //   if (lastScrollPosition < event) {
  //     scrollPosition = Math.ceil(event / projectWidth) * projectWidth
  //   } else if (lastScrollPosition > event) {
  //     scrollPosition = Math.floor(event / projectWidth) * projectWidth
  //   }

  //   if (scrollPosition !== event) {
  //     scrollEl.current.scrollTo({
  //       top: 0,
  //       left: scrollPosition,
  //       behavior: "smooth",
  //     })

  //     setLastScrollPosition(Math.floor(scrollPosition))
  //   }
  // }

  const unmountClean = () => {
    locoScroll && locoScroll.destroy()
    gsap.set('body', { maxHeight: "100vh", overflow: 'hidden' })
  }

  const playStartAnimation = () => {
    startTl.play()
  }

  const addToSectionsRefs = el => {
    if (el && !sectionsRefs.current.includes(el)) {
      sectionsRefs.current.push(el)
    }
  }

  const addLocomotiveScripts = () => {
    let localLocoScroll

    localLocoScroll = new window.LocomotiveScroll({
      el: document.querySelector(".scroll-container"),
      smooth: true,
      scrollFromAnywhere: true,
      reloadOnContextChange: true,
    })
    setLocoScroll(localLocoScroll)

    const heroAnimaton = () =>
      addImageScrollAnimation(heroRef.current, "header", "20% top+=50")

    setClearScrollAnimation(
      setScrollAnimation(localLocoScroll, sectionsRefs, heroAnimaton)
    )
  }

  useEffect(() => {
    const countSliderParams = () => {
      const windowWidth = window.innerWidth
      let containerWidth

      if (WIDTH_PERCENT * windowWidth >= MAX_WIDTH) {
        containerWidth = MAX_WIDTH
        setScrollPaddingLeft((windowWidth - MAX_WIDTH) / 2)
      } else {
        containerWidth = windowWidth * WIDTH_PERCENT
        setScrollPaddingLeft((windowWidth - containerWidth) / 2)
      }

      if (16 * 62 <= windowWidth) {
        setProjectWidth(containerWidth / 3)
      } else {
        setProjectWidth(containerWidth)
      }
    }

    countSliderParams()
    // window.LocomotiveScroll && addLocomotiveScripts()

    startTl
      .set(".animation-container", { autoAlpha: 1, transform: "none" })
      .from(heroRef.current.querySelector("img"), {
        scaleX: 0,
        scaleY: 1.2,
        ease: "expo",
        duration: 1,
        autoAlpha: 0,
      })
      .to(
        ".title-background",
        { scaleY: 0, transformOrigin: "center", opacity: 1 },
        "<0.5"
      )
      .from(".title-first", { yPercent: 100, opacity: 0.5 }, "<")
      .from(".title-second", { yPercent: -100, opacity: 0.5 }, "<")
      .from(heroRef.current.querySelector("p"), { y: 20, stagger: 0.1 }, "<")
      .from(
        heroRef.current.querySelector(".button"),
        { scaleX: 0, stagger: 0 },
        "<"
      )
      .from(
        heroRef.current.querySelector(".button span"),
        { autoAlpha: 0, opacity: 0, duration: 0.1, stagger: 0 },
        "<0.1"
      )

    playStartAnimation()
    return () => {
      clearScrollAnimation && clearScrollAnimation()
    }
  }, [])

  return (
    <Layout
      location={location}
      // title={siteTitle}
      className="animation-container scroll-container"
      locoScroll={locoScroll}
      addLocomotiveScripts={addLocomotiveScripts}
    >
      <SEO
        title="Bui-Pędowska Architekci - pracownia architektoniczna"
        description="Pracownia architektoniczna projektująca budynki mieszkalne jedno- i wielorodzinne, biurowe oraz użyteczności publicznej."
      />
      <section className="row section-with-nav" ref={heroRef}>
        <HeroImgContainer>
          <ResponsiveImage
            alt="Zdjęcie główne"
            src={mainPageData.hero_images}
            bigDivider={2}
            isFullWidth={true}
            // onLoadCallback={playStartAnimation}
          />
        </HeroImgContainer>
        <StyledContainer className="container">
          <div className="col-xs-12 col-md-5 col-md-offset-7 section-with-nav-content">
            <Title>
              <span className="title-first">Pracownia</span>
              <ThinTitle className="title-second">Architektoniczna</ThinTitle>
              <AnimationTitleBackground className="title-background" />
            </Title>
            <p className="big-text">{mainPageData.main_text}</p>
            <StyledButtonLink
              cover
              direction="right"
              bg="#fdfdfe"
              to="/projekty"
              className="button"
              onClick={unmountClean}
            >
              <span>Zobacz nasze projekty</span>
            </StyledButtonLink>
          </div>
        </StyledContainer>
      </section>
      <section className="container section-container" ref={addToSectionsRefs}>
        <div className="row reverse">
          <div className="col-xs-12 col-md-6 col-md-offset-1 col-lg-6 col-lg-offset-2">
            <SquereImg
              src={mainPageData.about_image}
              alt=""
              imgBigDivider={2}
            />
          </div>
          <div className="col-xs-12 col-md-5 col-lg-4">
            <h2>
              <StyledTitleLink
                cover
                direction="right"
                bg="#fdfdfe"
                to="/o_nas"
                onClick={unmountClean}
              >
                <StyledTitleLinkHover>Więcej </StyledTitleLinkHover>o pracowni
                <ArrowRight />
              </StyledTitleLink>
            </h2>
            <p>{mainPageData.about_text}</p>
          </div>
        </div>
      </section>
      <section className="container section-container">
        <h2 className="col-xs-12 center">Czym się zajmujemy?</h2>
        <WhatWeDo className="row start">
          {mainPageData.what_we_do.map((element, index) => {
            if (index % 2 === 0) {
              return (
                <div className="col-xs-6 col-md-3" key={element.title}>
                  <CollapsableBox
                    title={element.title}
                    description={element.description}
                    locoScroll={locoScroll}
                  />
                  <CollapsableBox
                    title={mainPageData.what_we_do[index + 1].title}
                    description={mainPageData.what_we_do[index + 1].description}
                    locoScroll={locoScroll}
                  />
                </div>
              )
            }

            return null
          })}
        </WhatWeDo>
      </section>
      <section className="row section-container" ref={addToSectionsRefs}>
        <div className="container">
          <h2 className="col-xs-12">
            <StyledTitleLink
              cover
              direction="right"
              bg="#fdfdfe"
              to="/projekty"
              onClick={unmountClean}
            >
              <StyledTitleLinkHover>Wszystkie </StyledTitleLinkHover>nasze
              projekty
              <ArrowRight />
            </StyledTitleLink>
          </h2>
        </div>
        <StyledScrollContainer
          innerRef={scrollEl}
          scrollPaddingLeft={scrollPaddingLeft}
          activationDistance={100}
          // onEndScroll={setScrollPosition}
        >
          <Slider>
            {projectWidth &&
              projects.map(
                ({
                  node: {
                    id,
                    frontmatter: { title, main_image, localization },
                    fields: { slug },
                  },
                }) => (
                  <StyledProjectPostLink
                    title={title}
                    imgSrc={main_image}
                    imgBigDivider={3}
                    localization={localization}
                    projectWidth={projectWidth}
                    slug={slug}
                    key={id}
                    currentPathname={location.pathname}
                    onClick={unmountClean}
                  />
                )
              )}
            {/* {projectWidth &&
              projects.map(
                ({
                  node: {
                    id,
                    frontmatter: { title, main_image, localization },
                    fields: { slug }
                  },
                }) => (
                  <StyledProjectPostLink
                    title={title}
                    imgSrc={main_image}
                    localization={localization}
                    projectWidth={projectWidth}
                    slug={slug}
                    key={`${id}1`}
                  />
                )
              )}
            {projectWidth &&
              projects.map(
                ({
                  node: {
                    id,
                    frontmatter: { title, main_image, localization },
                  },
                }) => (
                  <StyledProjectPostLink
                    title={title}
                    imgSrc={main_image}
                    localization={localization}
                    projectWidth={projectWidth}
                    key={`${id}2`}
                  />
                )
              )} */}
          </Slider>
        </StyledScrollContainer>
      </section>
      <ContactSection />
      {/* <SEO title="All posts" />
      {contactInfo.phone}
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol> */}
    </Layout>
  )
}

export default MainPage

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark(filter: { frontmatter: { main_page: { eq: true } } }) {
      edges {
        node {
          id
          frontmatter {
            main_image
            title
            localization
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
