import React, { useEffect, useState } from "react"
import styled from "styled-components"

const StyledArticle = styled.article`
  width: 100%;
  padding-bottom: 0.6rem;
  p {
    height: 0;
    overflow: hidden;
    transition: height ease-in-out 0.2s 0.2s;

    ${({ isOpen }) =>
      isOpen &&
      `
            height: 240px;
        `}
  }
`

const ClickableTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: none;
  border: none;
  border-top: 2px solid var(--black);
  text-align: left;
  padding: 1.8rem 0 1.2rem;
  cursor: pointer;

  outline: none;

  h3 {
    transition: all ease-in-out 0.2s;
  }

  &:hover,
  &:focus {
    h3 {
      transform: scale(0.97);
    }
  }
`

const StyledIcon = styled.svg`
  &,
  .plus-line {
    transition: all ease-in-out 0.2s;
    transform-origin: center;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
        .plus-line {
            transform: rotate(90deg);
        }
    `}

  ${ClickableTitle}:hover & {
    transform: rotate(180deg) scale(0.9);
  }
`

const PlusIcon = ({ isOpen, isHover }) => {
  return (
    <StyledIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isOpen={isOpen}
    >
      <path d="M16 8H0" stroke="#3B3B3C" strokeWidth="2" />
      <path
        d="M8 0V16"
        stroke="#3B3B3C"
        strokeWidth="2"
        className="plus-line"
      />
    </StyledIcon>
  )
}

const CollapsableBox = ({ title, description, locoScroll }) => {
  const [isOpen, setIsOpen] = useState(false)
  const splitedTitle = title.split(" ")

  useEffect(() => {
    setTimeout(() => {
      locoScroll && locoScroll.update()
    }, 500)
  }, [isOpen])

  return (
    <StyledArticle isOpen={isOpen}>
      <ClickableTitle onClick={() => setIsOpen(!isOpen)}>
        <h3>
          {splitedTitle.map((word, index) => (
            <span key={word}>
              {word} {index < splitedTitle.length && <br />}
            </span>
          ))}
        </h3>
        <PlusIcon isOpen={isOpen} />
      </ClickableTitle>
      <p>{description}</p>
    </StyledArticle>
  )
}

export default CollapsableBox
